import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

import { Button, ButtonProps } from '@emico/input'
import { theme } from '@emico/styles'

import IconChevronRight from '../components/IconChevronRight'

const StyledButton = styled(Button.original)`
    padding: 14px 20px;
    font-family: ${theme.fontFamilyPrimary};
    font-size: 15px;
    cursor: pointer;
`

const StyledChevron = styled(IconChevronRight)`
    width: 1em;
    margin-left: 7px;
    position: relative;
    top: 2px;
    left: 0;
    transition: left 0.2s ease;

    ${StyledButton}:hover & {
        left: 3px;
    }
`

interface ButtonWithArrowProps extends ButtonProps {
    disableArrow?: boolean
    children: ReactNode
}

const ButtonWithArrow = ({
    disableArrow,
    children,
    ...props
}: ButtonWithArrowProps) => (
    <StyledButton {...props}>
        {children}
        {!disableArrow && <StyledChevron />}
    </StyledButton>
)

Button.override(ButtonWithArrow)
