/* eslint-disable react/prop-types */
// TODO: fix this prop-types issue
import * as React from 'react'
import styled from '@emotion/styled'
import { Image } from '@emico/ui'
import { theme } from '@emico/styles'
import { Link } from '@emico/navigation'

const Banner = styled.div`
    margin: 0 0 10px 0;
    position: relative;
    background: ${theme.backgroundColor};
`
const StyledImage = styled(Image)`
    display: block;
    width: 100%;
    height: auto;
`
const StyledLink = styled(Link)`
    display: block;
`

interface BannerFullProps {
    image: {
        url: string
        alt: string
        dimensions: { width: number; height: number }
        copyright: string
    }
    link: string
}
const BannerFull = ({ image, link }: BannerFullProps) =>
    image && image.url ? (
        <Banner>
            <StyledLink
                to={link || '/'}
                analyticsName="Banner Full"
                analyticsContext="homepage.banner.full"
            >
                <StyledImage
                    url={image.url}
                    alt={image.alt || ''}
                    width={image.dimensions.width}
                    height={image.dimensions.height}
                />
            </StyledLink>
        </Banner>
    ) : null

export default BannerFull
