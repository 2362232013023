import { useQuery } from '@apollo/react-hooks'
import { PageWrapper } from '@emico/layout'
import { PrismicRichText } from '@emico/prismic'
import { H1 } from '@emico/ui'
import React from 'react'
import gql from 'graphql-tag'

import { PageBodyPlain, PageBodyPlainPrimary } from '../schema.generated'
import PrismicTitle from './Prismic/PrismicTitle'

interface Props {
    urlKey: string
}

const PrismicPage = ({ urlKey }: Props) => {
    const { data } = useQuery<
        {
            allPages: {
                edges: Array<{
                    node: {
                        body: Array<
                            Overwrite<
                                Pick<PageBodyPlain, 'primary' | '__typename'>,
                                {
                                    primary: Pick<
                                        PageBodyPlainPrimary,
                                        'title' | 'text' | 'image'
                                    >
                                }
                            >
                        >
                    }
                }>
            }
        },
        {
            storeView: string
            urlKey: string
        }
    >(
        gql`
            query($storeView: String!, $urlKey: String!) {
                allPages(
                    tags: [$storeView]
                    where: { url_key_fulltext: $urlKey }
                ) {
                    edges {
                        node {
                            body {
                                ... on PageBodyPlain {
                                    primary {
                                        title
                                        text
                                        image
                                    }
                                    __typename
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: {
                storeView: 'plattetv',
                urlKey,
            },
        },
    )
    const slices = data?.allPages.edges[0].node.body
    console.log(slices)

    return (
        <PageWrapper pageType="cms">
            {slices?.map((slice, index) => {
                if (slice.__typename !== 'PageBodyPlain') {
                    return null
                }

                return (
                    <div key={index}>
                        <PrismicTitle
                            render={slice.primary.title}
                            component={H1}
                        />

                        <PrismicRichText render={slice.primary.text} />
                    </div>
                )
            })}
        </PageWrapper>
    )
}

export default PrismicPage
