/* eslint-disable @typescript-eslint/ban-ts-ignore */
import * as React from 'react'
import { PageWrapper, Container } from '@emico/layout'

import Header from '../Header'
import Footer from '../Footer'
import SEOText from './SEOText'
import Hero from './Hero'
import Brands from './Brands'
import BannerFull from './BannerFull'

import { usePrismicHomepageSlice } from '../../__mocks__/usePrismicHomepageSlice'

const Homepage = () => {
    const { data } = usePrismicHomepageSlice()

    return (
        <PageWrapper pageType="N/A" header={<Header />} footer={<Footer />}>
            <Container noPadding>
                {data &&
                    data.map((slice, index) => {
                        if (slice.type === 'hero')
                            return (
                                <Hero
                                    key={index}
                                    // TODO: @Elyon de GraphQL call implementeren en types fixen
                                    // @ts-ignore
                                    imagemobile={slice.primary.imagemobile}
                                    // @ts-ignore
                                    imagedesktop={slice.primary.imagedesktop}
                                    // @ts-ignore
                                    title={slice.primary.title[0]?.text}
                                    // @ts-ignore
                                    link={slice.primary.link.url}
                                />
                            )

                        if (slice.type === 'banner_full')
                            return (
                                <BannerFull
                                    key={index}
                                    // @ts-ignore
                                    image={slice.primary.image}
                                    // @ts-ignore
                                    link={slice.primary.link.url}
                                />
                            )

                        if (slice.type === 'seo_text')
                            return (
                                <SEOText
                                    key={index}
                                    // @ts-ignore
                                    text={slice.primary.text}
                                />
                            )

                        if (slice.type === 'brands')
                            return (
                                <Brands
                                    key={index}
                                    // @ts-ignore
                                    title={slice.primary.title[0]?.text}
                                    // @ts-ignore
                                    items={slice.fields}
                                />
                            )

                        return null
                    })}
            </Container>
        </PageWrapper>
    )
}

export default Homepage
