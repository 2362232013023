import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CartPage } from '@emico/cart-ui'
import { PrismicPreviewResolver } from '@emico/prismic'

import DynamicRouteResolver from './DynamicRouteResolver'
import CheckoutSteps from '../components/CheckoutSteps'

import Homepage from '../components/Homepage/Homepage'

const Router = () => (
    <Switch>
        <Route path="/preview" exact component={PrismicPreviewResolver} />
        <Route path="/cart" exact component={CartPage} />
        <Route path="/checkout" component={CheckoutSteps} />
        <Route path="/" exact component={Homepage} />
        <Route component={DynamicRouteResolver} />
    </Switch>
)

export default Router
