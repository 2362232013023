import * as React from 'react'
import styled from '@emotion/styled'
import { Image } from '@emico/ui'
import { theme } from '@emico/styles'
import { Link } from '@emico/navigation'

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    background: ${theme.backgroundColor};
`
const Banner = styled.div`
    flex: 1 1 20%;
    max-width: 120px;
    margin: 10px;
`
const StyledImage = styled(Image)`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
`
const StyledLink = styled(Link)`
    display: block;
`
const Title = styled.h3`
    display: block;
    text-align: center;
    color: ${theme.gray700};
`

type PrismicBannerType = {
    image: {
        url: string
        alt: string
        dimensions: { width: number; height: number }
        copyright: string
    }
    link: { url: string }
}
interface Props {
    title: string
    items: PrismicBannerType[]
}
const Brands = ({ title, items }: Props) => (
    <>
        <Title>{title}</Title>
        <Wrapper>
            {items &&
                items.map((brand, index) => (
                    <Banner key={index}>
                        <StyledLink
                            title={brand.image.alt || ''}
                            to={brand.link?.url || '/'}
                            analyticsName="Brands"
                            analyticsContext="homepage.brands"
                        >
                            <StyledImage
                                url={brand.image.url}
                                alt={brand.image.alt || ''}
                                width={brand.image.dimensions.width}
                                height={brand.image.dimensions.height}
                            />
                        </StyledLink>
                    </Banner>
                ))}
        </Wrapper>
    </>
)

export default Brands
