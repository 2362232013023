import * as React from 'react'

import { ApolloConnector } from '@emico/apollo'
import { resolvers } from '@emico/cart-data'
import { I18nLoader } from '@emico/i18n'
import { BreakpointsProvider } from '@emico/breakpoints'
import { RouterProvider } from '@emico/navigation'
import { StoreView, StoreViewProvider } from '@emico/storeviews'
import { AuthorizationProvider } from '@emico/authentication'
import { PrismicPreviewLoader } from '@emico/prismic'

import dataIdFromObject from './dataIdFromObject'
import introspectionQueryResultData from './fragmentTypes.json'
import { Router } from './Router'

const App = ({
    storeViews,
    activeStoreView,
}: {
    storeViews: StoreView[]
    activeStoreView: StoreView
}) => (
    <>
        <PrismicPreviewLoader />

        <StoreViewProvider
            storeViews={storeViews}
            activeStoreView={activeStoreView}
        >
            <ApolloConnector
                introspectionQueryResultData={introspectionQueryResultData}
                dataIdFromObject={dataIdFromObject}
                resolvers={[resolvers]}
            >
                <I18nLoader>
                    <AuthorizationProvider>
                        <RouterProvider>
                            <BreakpointsProvider>
                                <Router />
                            </BreakpointsProvider>
                        </RouterProvider>
                    </AuthorizationProvider>
                </I18nLoader>
            </ApolloConnector>
        </StoreViewProvider>
    </>
)

export default App
