import * as React from 'react'
import { Header as LayoutHeader, TopBar } from '@emico/layout'

import USP from '../USP'
import Notice from './Notice'

TopBar.override(() => (
    <>
        <Notice />
        <USP type="header_usp" />
    </>
))

const Header = () => (
    <>
        <LayoutHeader />
    </>
)

export default Header
