import styled from '@emotion/styled'

import { ButtonSecondary } from '@emico/input'
import { theme } from '@emico/styles'

const StyledButtonSecondary = styled(ButtonSecondary.original)`
    border-bottom: 2px #826318 solid;
    padding-top: 16px;

    &:hover {
        background: ${theme.secondaryColor};
        color: ${theme.onSecondaryColor};
    }

    &:active,
    &:focus {
        transform: translateY(2px);
        margin-bottom: 2px;
        border-bottom-width: 0;
    }
`

ButtonSecondary.override(StyledButtonSecondary)
