import React, { FC, ReactNode } from 'react'
import styled from '@emotion/styled'
import { Processor, RichText } from 'prismic-reactjs'
import { Elements } from 'prismic-richtext'

const Wrapper = styled.span`
    display: block;

    p {
        margin: 0;
    }
`

// Source: https://github.com/prismicio/prismic-reactjs/blob/4d13db78dd4d296647745fc7ab2d8d29ea075e08/src/Component.js#L7
const createHtmlSerializer = (
    bucket = {},
    serializers: Array<{ type: string; fn: Processor }> = [],
): Processor => {
    const processors = serializers.reduce<{ [type: string]: Processor }>(
        (acc, { type, fn }) => Object.assign({}, acc, { [type]: fn }),
        {},
    )
    return (type, ...args) =>
        processors[type] ? processors[type](type, ...args) : null
}

type TitleComponent = FC<{
    children: ReactNode
}>

const renderer = (Component: TitleComponent): Processor => (
    type,
    element,
    content,
    children,
    index,
) => <Component key={index}>TODO</Component>

interface Props {
    render: object | string
    component: TitleComponent
}

const PrismicTitle = ({ render, component }: Props) => (
    <RichText
        Component={Wrapper}
        render={render}
        htmlSerializer={createHtmlSerializer({}, [
            {
                type: Elements.heading1,
                fn: renderer(component),
            },
        ])}
    />
)

export default PrismicTitle
