import * as React from 'react'
import { Container } from '@emico/layout'

import USP from '../USP'
import FooterBanner from './FooterBanner'
import FooterMenu from './FooterMenu'
import SubFooterIcons from './SubFooterIcons'
import SubFooterLinks from './SubFooterLinks'

const Footer = () => (
    <Container noPadding>
        <USP type="footer_usp" />
        <FooterBanner />
        <FooterMenu />
        <SubFooterIcons />
        <SubFooterLinks />
    </Container>
)

export default Footer
