import * as React from 'react'
import { Trans } from '@lingui/macro'
import styled from '@emotion/styled'
import { theme } from '@emico/styles'
import { Link } from '@emico/navigation'
import { useGetPrismicMain } from '@emico/prismic'

const Wrapper = styled.div`
    text-align: center;
    padding: 15px 0;
    margin: 0;

    a {
        color: ${theme.gray700};
        font-size: 12px;
        line-height: 18px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        + a:before {
            content: '|';
            padding: 0 8px;
        }
    }
`
const Copyright = styled.p`
    width: 100%;
    margin: 0;
    padding: 8px 0;
    color: ${theme.gray700};
    font-size: 12px;
    line-height: 18px;
    text-align: center;
`
const Text = styled.p`
    width: 100%;
    margin: 20px 0;
    padding: 8px 0;
    color: ${theme.gray700};
    font-size: 12px;
    line-height: 18px;
    text-align: center;
`

interface SliceData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any
    loading: boolean
}

const SubFooterLinks = () => {
    const now = new Date()
    const { data, loading }: SliceData = useGetPrismicMain(
        'footer_info',
        'nl-nl',
    )

    if (loading && !data) return null

    return (
        <Wrapper>
            {data?.items
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                .map(({ title, link }, index) => (
                    <Link
                        key={index}
                        to={link.url}
                        analyticsName="Legal Links"
                        analyticsContext="footer.legal"
                    >
                        {title[0].text}
                    </Link>
                ))}
            <Copyright>
                <Trans id="footer.copyright">
                    Copyright &copy; 2002 - {now.getFullYear()}. New Retail
                    Company B.V.
                </Trans>
            </Copyright>
            {data?.primary?.text?.[0]?.text && (
                <Text>{data.primary.text[0].text}</Text>
            )}
        </Wrapper>
    )
}

export default SubFooterLinks
