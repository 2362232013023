import styled from '@emotion/styled'

import { ButtonPrimary } from '@emico/input'

const StyledButtonPrimary = styled(ButtonPrimary.original)`
    background: transparent;
    color: currentColor;
    border: 2px currentColor solid;

    &:hover {
        background: transparent;
        color: currentColor;
    }
`

ButtonPrimary.override(StyledButtonPrimary)
