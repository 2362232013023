import React from 'react'
import ReactDOM from 'react-dom'

import { getCatalogLanguage, initializeI18n } from '@emico/i18n'
import { getActiveStoreView } from '@emico/storeviews'

import './index.css'
import './theme'
import './overrides'

import App from './App'
import storeViews from './storeViews'

const activeStoreView = getActiveStoreView(storeViews, window.location)

const loadCatalog = (lang: string) =>
    process.env.NODE_ENV !== 'production'
        ? import(
              /* webpackMode: "lazy", webpackChunkName: "i18n-[request]" */ `@lingui/loader!./locales/${lang}/messages.json`
          )
        : import(
              /* webpackMode: "lazy", webpackChunkName: "i18n-[request]" */ `./locales/${lang}/messages.js`
          )

// See the README for @emico/i18n why delaying the App mounting makes sense.
initializeI18n(getCatalogLanguage(activeStoreView), loadCatalog).then(() => {
    ReactDOM.render(
        <App storeViews={storeViews} activeStoreView={activeStoreView} />,
        document.getElementById('root'),
    )
})
