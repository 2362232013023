export type Field = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}
export type Link = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

const slice = {
    data: {
        content: {
            body: [
                {
                    type: 'notice_banner',
                    primary: {
                        notice: [
                            {
                                type: 'paragraph',
                                text:
                                    'UPDATE: Onze Winkels zijn geopend - Bekijk alle maatregelen',
                                spans: [
                                    {
                                        start: 13,
                                        end: 21,
                                        type: 'strong',
                                    },
                                    {
                                        start: 13,
                                        end: 20,
                                        type: 'hyperlink',
                                        data: {
                                            // eslint-disable-next-line
                                            link_type: 'Web',
                                            url: 'http://localhost:3340/test',
                                        },
                                    },
                                    {
                                        start: 36,
                                        end: 59,
                                        type: 'hyperlink',
                                        data: {
                                            // eslint-disable-next-line
                                            link_type: 'Web',
                                            url:
                                                'https://www.allesvoorbbq.nl/coronavirus-maatregelen',
                                            target: '_blank',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    type: 'header_usp',
                    fields: [
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: '7 vestigingen',
                                    spans: [],
                                },
                            ],
                        },
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: 'Installatie en montage',
                                    spans: [],
                                },
                            ],
                        },
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: 'Gratis bezorging',
                                    spans: [],
                                },
                            ],
                        },
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: 'Klantbeoordeling: 9,3',
                                    spans: [],
                                },
                            ],
                        },
                    ] as Field[],
                },
                {
                    type: 'footer_usp',
                    fields: [
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: '7 vestigingen',
                                    spans: [],
                                },
                            ],
                        },
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: '7 dagen per week open',
                                    spans: [],
                                },
                            ],
                        },
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: '2500m2 BBQ-beleving',
                                    spans: [],
                                },
                            ],
                        },
                        {
                            usp: [
                                {
                                    type: 'heading5',
                                    text: 'Gratis bezorgd',
                                    spans: [],
                                },
                            ],
                        },
                    ] as Field[],
                },
                {
                    type: 'footer_menu',
                    fields: [
                        {
                            menu: {
                                title: [
                                    {
                                        type: 'heading3',
                                        text: 'ALLESVOORBBQ',
                                        spans: [],
                                    },
                                ],
                                links: [
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Contact',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/contact',
                                        },
                                    },
                                ] as Link[],
                            },
                        },
                        {
                            menu: {
                                title: [
                                    {
                                        type: 'heading3',
                                        text: 'ONZE WINKELS',
                                        spans: [],
                                    },
                                ],
                                links: [
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Amsterdam',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/onze-winkels/amsterdam',
                                        },
                                    },
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Breda',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/onze-winkels/breda',
                                        },
                                    },
                                ] as Link[],
                            },
                        },
                        {
                            menu: {
                                title: [
                                    {
                                        type: 'heading3',
                                        text: 'AFHAALPUNTEN',
                                        spans: [],
                                    },
                                ],
                                links: [
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Arnhem',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/onze-winkels/arnhem',
                                        },
                                    },
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Nijmegen',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/onze-winkels/nijmegen',
                                        },
                                    },
                                ] as Link[],
                            },
                        },
                        {
                            menu: {
                                title: [
                                    {
                                        type: 'heading3',
                                        text: 'POPULAIRE CATEGORIEËN',
                                        spans: [],
                                    },
                                ],
                                links: [
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Gas BBQ',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/gas-bbq.html',
                                        },
                                    },
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Houtskool BBQ',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/houtskool-bbq.html',
                                        },
                                    },
                                ] as Link[],
                            },
                        },
                        {
                            menu: {
                                title: [
                                    {
                                        type: 'heading3',
                                        text: 'POPULAIRE MERKEN',
                                        spans: [],
                                    },
                                ],
                                links: [
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Weber',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/weber.html',
                                        },
                                    },
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'Boretti',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/boretti.html',
                                        },
                                    },
                                    {
                                        // eslint-disable-next-line
                                        link_title: [
                                            {
                                                type: 'heading5',
                                                text: 'The bastard',
                                                spans: [],
                                            },
                                        ],
                                        link: {
                                            url:
                                                'https://www.allesvoorbbq.nl/the-bastard-merk.html',
                                        },
                                    },
                                ] as Link[],
                            },
                        },
                    ] as Field[],
                },
                {
                    type: 'footer_sub_icons',
                    fields: [
                        {
                            image: {
                                dimensions: {
                                    width: 128,
                                    height: 128,
                                },
                                alt: 'ideal',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/034adf17-ee02-4c51-bca2-75e6db35def0_ideal-128.png?auto=compress,format&rect=0,0,128,128&w=128&h=128',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 128,
                                    height: 128,
                                },
                                alt: 'bancontact',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/ad105e84-617f-41be-91da-31661b8df488_New+Project+%285%29.png?auto=compress,format&rect=0,0,128,128&w=128&h=128',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 128,
                                    height: 128,
                                },
                                alt: 'mastercard',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/2b758790-e39c-4391-a9ba-4f029785b46e_New+Project+%282%29.png?auto=compress,format&rect=0,0,128,128&w=128&h=128',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 128,
                                    height: 128,
                                },
                                alt: 'visa',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/88ba466f-330a-4a05-8c0d-58786f7c66cd_New+Project+%283%29.png?auto=compress,format&rect=0,0,128,128&w=128&h=128',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 128,
                                    height: 128,
                                },
                                alt: 'postnl',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/42d1d78e-92bd-49d8-aaa8-597b55a40f8f_New+Project+%284%29.png?auto=compress,format&rect=0,0,128,128&w=128&h=128',
                            },
                        },
                    ] as Field[],
                },
                {
                    type: 'footer_sub_legal',
                    primary: {
                        copyright: [
                            {
                                type: 'paragraph',
                                text:
                                    'Alle vermelde prijzen zijn inclusief BTW',
                                spans: [],
                            },
                        ],
                    },
                    fields: [
                        {
                            title: [
                                {
                                    type: 'heading5',
                                    text: 'Algemene voorwaarden',
                                    spans: [],
                                },
                            ],
                            link: {
                                url: 'https://www.allesvoorbbq.nl/voorwaarden',
                            },
                        },
                        {
                            title: [
                                {
                                    type: 'heading5',
                                    text: 'Privacy',
                                    spans: [],
                                },
                            ],
                            link: {
                                url:
                                    'https://www.allesvoorbbq.nl/privacybeleid',
                            },
                        },
                        {
                            title: [
                                {
                                    type: 'heading5',
                                    text: 'Cookies',
                                    spans: [],
                                },
                            ],
                            link: {
                                url: 'https://www.allesvoorbbq.nl/cookiebeleid',
                            },
                        },
                    ] as Field[],
                },
                {
                    type: 'footer_banner',
                    primary: {
                        text: [
                            {
                                type: 'heading3',
                                text: 'HULP NODIG?',
                                spans: [],
                            },
                            {
                                type: 'paragraph',
                                text: 'Klantenservice vandaag open tot 21:00',
                                spans: [],
                            },
                        ],
                        banner: {
                            dimensions: {
                                width: 200,
                                height: 200,
                            },
                            alt: 'Hulp nodig?',
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/6de0818b-dd40-41db-b8a8-ee16771d80aa_tim.png?auto=compress,format&rect=0,0,400,400&w=200&h=200',
                        },
                    },
                    fields: [
                        {
                            icon: {
                                dimensions: {
                                    width: 64,
                                    height: 64,
                                },
                                alt: 'Chat',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/6e9effc4-18e9-4487-a47b-58683e16102f_comment-regular.svg?auto=compress,format&rect=0,0,150,150&w=64&h=64',
                            },
                            title: [
                                {
                                    type: 'heading3',
                                    text: 'CHAT LIVE',
                                    spans: [],
                                },
                            ],
                            link: {
                                url: 'https://www.allesvoorbbq.nl/',
                            },
                        },
                        {
                            icon: {
                                dimensions: {
                                    width: 64,
                                    height: 64,
                                },
                                alt: 'E-mail',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/8d2e9b3c-82b0-4977-bc8a-1ff937eb1da1_envelope-regular.svg?auto=compress,format&rect=0,0,150,150&w=64&h=64',
                            },
                            title: [
                                {
                                    type: 'heading3',
                                    text: 'E-MAIL',
                                    spans: [],
                                },
                            ],
                            link: {
                                url: 'mailto:contact@allesvoorbbq.nl',
                            },
                        },
                        {
                            icon: {
                                dimensions: {
                                    width: 64,
                                    height: 64,
                                },
                                alt: 'Whatsapp',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/dbc4fcc5-bacc-4606-afdd-5e5d78c3559a_whatsapp-brands.svg?auto=compress,format&rect=0,10,131,131&w=64&h=64',
                            },
                            title: [
                                {
                                    type: 'heading3',
                                    text: 'WHATSAPP ONS',
                                    spans: [],
                                },
                            ],
                            link: {
                                url:
                                    'https://api.whatsapp.com/send?phone=31645403030',
                            },
                        },
                        {
                            icon: {
                                dimensions: {
                                    width: 64,
                                    height: 64,
                                },
                                alt: 'Onze winkels',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/1c2abf83-9829-40ca-9f1c-f0f9e4ce4ade_store-solid.svg?auto=compress,format&rect=14,0,150,150&w=64&h=64',
                            },
                            title: [
                                {
                                    type: 'heading3',
                                    text: 'BEZOEK',
                                    spans: [],
                                },
                                {
                                    type: 'paragraph',
                                    text: 'Onze winkels',
                                    spans: [],
                                },
                            ],
                            link: {
                                url: 'https://www.allesvoorbbq.nl/onze-winkels',
                            },
                        },
                    ] as Field[],
                },
                {
                    type: 'footer_social',
                    primary: {
                        title: [
                            {
                                type: 'heading5',
                                text: 'VOLG ONS:',
                                spans: [],
                            },
                        ],
                    },
                    fields: [
                        {
                            icon: {
                                dimensions: {
                                    width: 64,
                                    height: 64,
                                },
                                alt: 'Facebook',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/e62ff9c0-2c46-47b9-baa7-69c37e064c81_facebook.png?auto=compress,format&rect=0,0,64,64&w=64&h=64',
                            },
                            link: {
                                url: 'https://www.facebook.com/allesvoorbbq',
                            },
                        },
                        {
                            icon: {
                                dimensions: {
                                    width: 64,
                                    height: 64,
                                },
                                alt: 'Youtube',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/74e7cb02-17f9-4693-81c7-0ebd26b8d850_youtube.png?auto=compress,format&rect=0,0,64,64&w=64&h=64',
                            },
                            link: {
                                url:
                                    'https://www.youtube.com/user/allesvoor3dtv',
                            },
                        },
                        {
                            icon: {
                                dimensions: {
                                    width: 64,
                                    height: 64,
                                },
                                alt: 'Instagram',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/1e0b5c4b-6dc6-4a70-ae71-c2f7a47d96c6_instagram.png?auto=compress,format&rect=0,0,64,64&w=64&h=64',
                            },
                            link: {
                                url: 'https://www.instagram.com/allesvoorbbq/',
                            },
                        },
                    ] as Field[],
                },
            ],
        },
    },
}

export const usePrismicSlice = (type: string) => ({
    data: slice.data.content.body.find(slice => slice.type === type),
})

// eslint-disable-next-line
const query = `
query PrismicContent($uid: String!, $lang: String!) {
    content(uid: $uid, lang: $lang) {
      body {
        ... on ContentBodyNotice_banner {
          type
          primary {
            notice
          }
        }
        ... on ContentBodyHeader_usp {
          type
          fields {
            usp
          }
        }
        ... on ContentBodyFooter_usp {
          type
          fields {
            usp
          }
        }
        ... on ContentBodyFooter_sub_icons {
          type
          fields {
            image
          }
        }
        ... on ContentBodyFooter_sub_legal {
          type
          primary {
            copyright
          }
          fields {
            title
            link {
              ... on _ExternalLink {
                url
              }
            }
          }
        }
        ... on ContentBodyFooter_banner {
          type
          primary {
            text
            banner
          }
          fields {
            icon
            title
            link {
              ... on _ExternalLink {
                url
              }
            }
          }
        }
        ... on ContentBodyFooter_social {
          type
          primary {
            title
          }
          fields {
            icon
            link {
              ... on _ExternalLink {
                url
              }
            }
          }
        }
        ... on ContentBodyFooter_menu {
          type
          fields {
            menu {
              ... on Menu {
                title
                links {
                  ... on MenuLinks {
                    link_title
                    link {
                      ... on _ExternalLink {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
`
