import * as React from 'react'
import styled from '@emotion/styled'

import { usePrismicSlice } from '../../__mocks__/usePrismicSlice'

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 0 0;
`
const ImageWrapper = styled.div`
    margin: 0 8px;
    width: 48px;
    height: 32px;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
`

const FooterPaymentIcons = () => {
    const { data } = usePrismicSlice('footer_sub_icons')

    return (
        <Wrapper>
            {data?.fields?.map(({ image }, index) => (
                <ImageWrapper key={index}>
                    <img
                        src={image.url}
                        alt={image.alt}
                        width={image.dimensions.width}
                        height={image.dimensions.height}
                    />
                </ImageWrapper>
            ))}
        </Wrapper>
    )
}

export default FooterPaymentIcons
