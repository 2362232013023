import { t } from '@lingui/macro'
import i18n from '@emico/i18n'
import React from 'react'
import {
    MultipleSteps,
    ContactDetailsPage,
    LoginPage,
    DeliveryMethodSelectionPage,
    PaymentMethodPage,
    ReviewPage,
    PaymentPendingPage,
    StepType,
    checkoutRoutes,
    useCheckoutShouldCreateAccount,
} from '@emico/checkout'
import { useIsLoggedIn } from '@emico/authentication'
import { useGetCart } from '@emico/cart-data'

enum Step {
    Login = 1,
    ContactDetails = 2,
    ShippingAddress = 3,
    DeliveryMethodSelection = 4,
    PaymentMethodSelection = 5,
    Review = 6,
    Payment = 7,
}

function useSteps(): StepType[] {
    const isLoggedIn = useIsLoggedIn()
    const { data: createAccountData } = useCheckoutShouldCreateAccount()
    const { data: cart } = useGetCart()
    return [
        {
            path: checkoutRoutes.login,
            component: LoginPage,
            step: Step.Login,
            label: i18n._(t('checkout.steps.login')`Login`),
            isDisabled: () => isLoggedIn,
        },
        {
            path: checkoutRoutes.contactDetails,
            component: ContactDetailsPage,
            step: Step.ContactDetails,
            label: i18n._(t('checkout.steps.contactDetails')`Contact details`),
            isDisabled: () =>
                !isLoggedIn &&
                createAccountData?.checkoutShouldCreateAccount === undefined,
        },
        {
            path: checkoutRoutes.deliveryMethodSelection,
            component: DeliveryMethodSelectionPage,
            step: Step.DeliveryMethodSelection,
            label: i18n._(t('checkout.steps.deliveryMethod')`Delivery method`),
            isDisabled: () =>
                !cart?.shippingAddresses || cart.shippingAddresses.length < 1,
        },
        {
            path: checkoutRoutes.paymentMethodSelection,
            component: PaymentMethodPage,
            step: Step.PaymentMethodSelection,
            label: i18n._(t('checkout.steps.paymentMethod')`Payment method`),
            isDisabled: () =>
                !cart?.shippingAddresses ||
                cart?.shippingAddresses[0]?.selectedShippingMethod ===
                    undefined,
        },
        {
            path: checkoutRoutes.review,
            component: ReviewPage,
            step: Step.Review,
            label: i18n._(t('checkout.steps.review')`Review`),
            isDisabled: () => true,
        },
        {
            path: checkoutRoutes.paymentPending,
            component: PaymentPendingPage,
            step: Step.Payment,
            label: i18n._(t('checkout.steps.payment')`Payment`),
            isDisabled: () => true,
        },
    ]
}

const CheckoutSteps = () => {
    const steps = useSteps()
    return <MultipleSteps steps={steps} />
}

export default CheckoutSteps
