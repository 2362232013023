import { defaultDataIdFromObject, IdGetter } from 'apollo-cache-inmemory'

import { dataIdFromObject as cartDataIdFromObject } from '@emico/cart-data'
import { ResolvedUrl } from '@emico/navigation'

const dataIdFromObject: IdGetter = object => {
    const cartDataId = cartDataIdFromObject(object)
    if (cartDataId !== undefined) {
        return cartDataId
    }

    switch (object.__typename) {
        case 'EntityUrl': {
            const entityUrl = (object as unknown) as ResolvedUrl
            return `${object.__typename}:${entityUrl.type}:${entityUrl.id}`
        }
        default:
            return defaultDataIdFromObject(object)
    }
}

export default dataIdFromObject
