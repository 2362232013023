import { overrideTheme } from '@emico/styles'

overrideTheme({
    primaryColor: '#2da6c1',
    onPrimaryColor: '#fff',
    secondaryColor: '#fdc642',
    onSecondaryColor: '#212226',
    secondaryVariantColor: '#e6e6dc',
    onSecondaryVariantColor: '#212226',
    gray900: '#212226',
    fontFamilyPrimary: "'Poppins SemiBold', sans-serif",
})
