import * as React from 'react'
import styled from '@emotion/styled'
import { theme, minWidth } from '@emico/styles'
import { Link } from '@emico/navigation'

import { usePrismicSlice } from '../../__mocks__/usePrismicSlice'

const Wrapper = styled.div`
    background: ${theme.gray900};
    padding: 32px 38px 50px 38px;

    @media ${minWidth('md')} {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
    }
`
const Menu = styled.div`
    flex: 0 1 auto;
`
const MenuTitle = styled.h3`
    margin: 30px 0 10px 0;
    font-size: 16px;
    line-height: 22px;
    color: ${theme.onPrimaryColor};

    @media ${minWidth('md')} {
        margin: 0 0 10px 0;
    }
`
const MenuList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`
const MenuItem = styled.li`
    a {
        color: ${theme.onPrimaryColor};
        font-size: 14px;
        line-height: 22px;
        text-decoration: none;
        transition: color 200ms ease-out;

        &:hover {
            color: ${theme.secondaryColor};
        }
    }
`

const FooterMenu = () => {
    const { data } = usePrismicSlice('footer_menu')

    return (
        <Wrapper>
            {data?.fields?.map((item, index) => (
                <Menu key={index}>
                    <MenuTitle>{item.menu.title[0].text}</MenuTitle>
                    <MenuList>
                        {item.menu.links
                            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                            // @ts-ignore
                            .map((item, index) => (
                                <MenuItem key={index}>
                                    <Link
                                        to={item.link.url}
                                        analyticsName="Footer"
                                        analyticsContext="footer.menu"
                                    >
                                        {item.link_title[0].text}
                                    </Link>
                                </MenuItem>
                            ))}
                    </MenuList>
                </Menu>
            ))}
        </Wrapper>
    )
}

export default FooterMenu
