import * as React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme, minWidth } from '@emico/styles'
import { PrismicRichText } from '@emico/prismic'
import { Link } from '@emico/navigation'

import { usePrismicSlice } from '../../__mocks__/usePrismicSlice'

interface ImageProps {
    url: string
}
const Wrapper = styled.div`
    padding: 20px 38px 0 38px;
    min-height: 200px;
    background: ${theme.secondaryVariantColor};

    @media ${minWidth('md')} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
`
const Banner = styled.div<ImageProps>(
    ({ url }) => css`
        flex: 0 1 25%;
        background: url(${url}) no-repeat left bottom;
        background-size: auto;
    `,
)
const Main = styled.div`
    flex: 0 1 50%;
`
const MainText = styled.div`
    margin-bottom: 20px;

    h3 {
        margin: 0;
    }

    p {
        font-size: 12px;
        line-height: 15px;
    }
`
const Actions = styled.div`
    display: flex;
    flex-wrap: wrap;
`
const ActionTitle = styled.span`
    display: flex;
    align-items: center;
    min-height: 40px;

    > span {
        h3 {
            width: 100%;
            margin: 0;
        }

        p {
            font-size: 12px;
            line-height: 15px;
        }
    }
`
const StyledActionLink = styled(Link)`
    flex: 1 0 50%;
    text-decoration: none;
    margin-bottom: 15px;
`
const Icon = styled.span<ImageProps>(
    ({ url }) => css`
        display: inline-block;
        vertical-align: top;
        width: 32px;
        height: 32px;
        margin-right: 15px;
        border-radius: 16px;
        background: white url(${url}) no-repeat center center;
        background-size: 18px;
    `,
)
const Social = styled.div`
    display: flex;
    flex: 0 1 25%;
    align-items: flex-end;
    padding: 15px 0;

    h5 {
        display: inline-block;
        vertical-align: top;
        line-height: 24px;
        margin: 0;
    }
`
const SocialIcon = styled.span<ImageProps>(
    ({ url }) => css`
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        margin-left: 6px;
        background: url(${url}) no-repeat center center;
        background-size: 24px;
        border-radius: 6px;
    `,
)

const FooterBanner = () => {
    const { data } = usePrismicSlice('footer_banner')
    const { data: social } = usePrismicSlice('footer_social')

    return (
        <Wrapper>
            {data?.primary?.banner && <Banner url={data.primary.banner.url} />}

            <Main>
                <MainText>
                    {data?.primary?.text && (
                        <PrismicRichText render={data.primary.text} />
                    )}
                </MainText>

                <Actions>
                    {data?.fields?.map(({ icon, title, link }, index) => (
                        <StyledActionLink
                            key={index}
                            to={link?.url}
                            analyticsName="Action Link"
                            analyticsContext="footer.actions"
                        >
                            <ActionTitle>
                                <Icon url={icon.url} />
                                <PrismicRichText render={title} />
                            </ActionTitle>
                        </StyledActionLink>
                    ))}
                </Actions>
            </Main>

            <Social>
                {social?.primary?.title && (
                    <PrismicRichText render={social.primary.title} />
                )}

                {social?.fields?.map(({ icon, link }, index) => (
                    <Link
                        key={index}
                        to={link?.url}
                        analyticsName="Action Banner"
                        analyticsContext="footer.actions"
                    >
                        <SocialIcon url={icon.url} />
                    </Link>
                ))}
            </Social>
        </Wrapper>
    )
}

export default FooterBanner
