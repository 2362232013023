import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '@emico/styles'
import { PrismicRichText } from '@emico/prismic'

const Wrapper = styled.div`
    padding: 18px 38px 28px 38px;
    background: ${theme.backgroundColor};
`

interface SEOTextProps {
    text: object
}
// TODO: Fix this prop-types issue
// eslint-disable-next-line react/prop-types
const SEOText = ({ text }: SEOTextProps) =>
    text ? (
        <Wrapper>
            <PrismicRichText render={text} />
        </Wrapper>
    ) : null

export default SEOText
