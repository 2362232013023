import { StoreView } from '@emico/storeviews'
import { t } from '@lingui/macro'

const storeViews: StoreView[] = [
    {
        code: 'platte_tv_nl',
        language: 'en',
        locale: undefined,
        name: t('storeViews.example')`International`,
        basename: '',
        location: undefined,
        makeUrl: relativePath => relativePath,
        default: true,
        storeId: 1,
        websiteId: 1,
    },
]
export default storeViews
