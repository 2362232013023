import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '@emico/styles'
import { Image } from '@emico/ui'
import { useBreakpoints } from '@emico/breakpoints'
import { ButtonSecondary } from '@emico/input'
import { Link } from '@emico/navigation'

const Banner = styled.div`
    margin: 0 0 10px 0;
    position: relative;
    background: ${theme.backgroundColor};
`
const StyledImage = styled(Image)`
    display: block;
    width: 100%;
    height: auto;
`
const StyledLink = styled(Link)`
    display: block;
`
const Button = styled(ButtonSecondary)`
    left: 20px;
    bottom: 20px;
    position: absolute;
`
interface PrismicImageProps {
    url: string
    alt: string
    dimensions: { width: number; height: number }
    copyright: string
}
interface Props {
    imagemobile: PrismicImageProps
    imagedesktop: PrismicImageProps
    title: string
    link: string
}
const Hero = ({ imagemobile, imagedesktop, title, link }: Props) => {
    const { isMobile } = useBreakpoints()

    return (
        <Banner>
            <StyledLink
                title={imagedesktop.alt || ''}
                to={link || '/'}
                analyticsName="Hero"
                analyticsContext="homepage.hero"
            >
                {isMobile && imagemobile?.url ? (
                    <StyledImage
                        url={imagemobile.url}
                        alt={imagemobile.alt || ''}
                        width={imagemobile.dimensions.width}
                        height={imagemobile.dimensions.height}
                    />
                ) : (
                    <StyledImage
                        url={imagedesktop.url}
                        alt={imagedesktop.alt || ''}
                        width={imagedesktop.dimensions.width}
                        height={imagedesktop.dimensions.height}
                    />
                )}
                <Button analyticsCategory="hero" analyticsName="hero">
                    {title}
                </Button>
            </StyledLink>
        </Banner>
    )
}

export default Hero
