import * as React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme, minWidth } from '@emico/styles'

import { usePrismicSlice } from '../../__mocks__/usePrismicSlice'

interface Props {
    type: 'header_usp' | 'footer_usp'
}
const Wrapper = styled.ul<Props>(
    ({ type }) => css`
        list-style: none;
        margin: 0;

        @media ${minWidth('md')} {
            display: flex;
            justify-content: center;
        }

        ${type === 'header_usp' && `background: ${theme.gray900};`}
    `,
)
const Item = styled.li<Props>(
    ({ type }) => css`
        position: relative;
        flex: 0 1 auto;
        padding: 0 18px;
        margin: 0 18px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 28px;

        ${type === 'header_usp' && `color: ${theme.onPrimaryColor};`}

        > span {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -18px;
                top: 3px;
                width: 10px;
                height: 10px;
                background: ${theme.primaryColor};
                border-radius: 50%;
            }
        }
    `,
)

const USP = ({ type }: Props) => {
    const { data } = usePrismicSlice(type)

    return (
        <Wrapper type={type}>
            {data?.fields?.map((item, index) => (
                <Item type={type} key={index}>
                    <span>{item.usp[0].text}</span>
                </Item>
            ))}
        </Wrapper>
    )
}

export default USP
