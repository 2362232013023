const slice = {
    data: {
        homepage: {
            body: [
                {
                    type: 'hero',
                    primary: {
                        imagemobile: {
                            dimensions: {
                                width: 960,
                                height: 640,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=412,0,1211,807&w=960&h=640',
                        },
                        imagedesktop: {
                            dimensions: {
                                width: 1920,
                                height: 840,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=95,0,1845,807&w=1920&h=840',
                        },
                        title: [
                            {
                                type: 'heading5',
                                text: 'NAAR DE ACTIE',
                                spans: [],
                            },
                        ],
                        link: {
                            url:
                                'https://www.allesvoorbbq.nl/elektrische-bbq.html',
                        },
                    },
                },
                {
                    type: 'banner_full',
                    primary: {
                        image: {
                            dimensions: {
                                width: 1920,
                                height: 640,
                            },
                            alt: 'full 1',
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=0,129,2035,678&w=1920&h=640',
                        },
                        link: {
                            url:
                                'https://www.allesvoorbbq.nl/elektrische-bbq.html',
                        },
                    },
                },
                {
                    type: 'banner_half_half',
                    primary: {
                        imageone: {
                            dimensions: {
                                width: 960,
                                height: 640,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=412,0,1211,807&w=960&h=640',
                        },
                        linkone: null,
                        imagetwo: {
                            dimensions: {
                                width: 960,
                                height: 640,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=412,0,1211,807&w=960&h=640',
                        },
                        linktwo: null,
                    },
                },
                {
                    type: 'banner_twothird_onethird',
                    primary: {
                        imageonethird: {
                            dimensions: {
                                width: 640,
                                height: 640,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=434,0,807,807&w=640&h=640',
                        },
                        linkonethird: {
                            url:
                                'https://www.allesvoorbbq.nl/buitenkeukens.html',
                        },
                        imagetwothird: {
                            dimensions: {
                                width: 1280,
                                height: 640,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=421,0,1614,807&w=1280&h=640',
                        },
                        linktwothird: {
                            url:
                                'https://www.allesvoorbbq.nl/buitenkeukens.html',
                        },
                    },
                },
                {
                    type: 'banner_onethird_twothird',
                    primary: {
                        imageonethird: {
                            dimensions: {
                                width: 640,
                                height: 640,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=614,0,807,807&w=640&h=640',
                        },
                        linkonethird: {
                            url:
                                'https://www.allesvoorbbq.nl/klantenservice/overallesvoorbbq.nl',
                        },
                        imagetwothird: {
                            dimensions: {
                                width: 1280,
                                height: 640,
                            },
                            alt: null,
                            copyright: null,
                            url:
                                'https://images.prismic.io/newretailcompany/84b33d5e-ac24-4d51-807b-ff186e8fa4f7_banner-1920x640.jpeg?auto=compress,format&rect=211,0,1614,807&w=1280&h=640',
                        },
                        linktwothird: {
                            url:
                                'https://www.allesvoorbbq.nl/klantenservice/overallesvoorbbq.nl',
                        },
                    },
                },
                {
                    type: 'brands',
                    primary: {
                        title: [
                            {
                                type: 'heading3',
                                text: 'OFFICIËLE DEALER VAN DE BESTE MERKEN',
                                spans: [],
                            },
                        ],
                    },
                    fields: [
                        {
                            image: {
                                dimensions: {
                                    width: 240,
                                    height: 120,
                                },
                                alt: 'Weber',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/20847d38-4e1e-43f9-9459-2dc5bf60e7bd_weber-240.png?auto=compress,format&rect=0,0,240,120&w=240&h=120',
                            },
                            link: {
                                url: 'https://www.allesvoorbbq.nl/weber.html',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 240,
                                    height: 120,
                                },
                                alt: 'Boretti',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/cb93cf3c-3729-49fa-b30b-28ebbd094937_boretti-240.png?auto=compress,format&rect=0,0,240,120&w=240&h=120',
                            },
                            link: {
                                url: 'https://www.allesvoorbbq.nl/weber.html',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 240,
                                    height: 120,
                                },
                                alt: 'GrandHall',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/4bed5944-42f0-4073-820b-565bc0863b3a_grandhall-240.png?auto=compress,format&rect=0,0,240,120&w=240&h=120',
                            },
                            link: {
                                url: 'https://www.allesvoorbbq.nl/weber.html',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 240,
                                    height: 120,
                                },
                                alt: 'Cadac',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/babc53a3-60aa-4d83-8a03-426b33ebf882_cadac-240.png?auto=compress,format&rect=0,0,240,120&w=240&h=120',
                            },
                            link: {
                                url: 'https://www.allesvoorbbq.nl/weber.html',
                            },
                        },
                        {
                            image: {
                                dimensions: {
                                    width: 240,
                                    height: 120,
                                },
                                alt: 'Big Green Egg',
                                copyright: null,
                                url:
                                    'https://images.prismic.io/newretailcompany/5e6f4605-0a41-4f35-8324-0f5e2d867e01_big-green-egg-240.png?auto=compress,format&rect=0,0,240,120&w=240&h=120',
                            },
                            link: {
                                url: 'https://www.allesvoorbbq.nl/weber.html',
                            },
                        },
                    ],
                },
                {
                    type: 'seo_text',
                    primary: {
                        text: [
                            {
                                type: 'heading3',
                                text:
                                    'Wilt u jaren genieten van uw nieuwe barbecue? Laat u adviseren door onze specialisten.',
                                spans: [],
                            },
                            {
                                type: 'paragraph',
                                text: '',
                                spans: [],
                            },
                            {
                                type: 'paragraph',
                                text:
                                    "In onze webwinkel of in één van onze mooie winkels in Amsterdam, Breda, Doetinchem, Eindhoven, Naarden, Utrecht en Zoeterwoude kunt u op uw gemak rondkijken en een keuze maken uit het brede aanbod aan BBQ's. Onze medewerkers helpen u graag hierbij en geven u desgewenst persoonlijk advies over al onze producten. Uiteraard kunt u ook per mail of telefonisch advies inwinnen. Zo weet u zeker dat u de juiste keuze maakt waarvan u jarenlang kunt genieten.",
                                spans: [],
                            },
                        ],
                    },
                },
            ],
        },
    },
}

export const usePrismicHomepageSlice = () => ({
    data: slice.data.homepage.body,
})

// eslint-disable-next-line
const query = `
query PrismicHomepage($uid: String!, $lang: String!) {
  homepage(uid: $uid, lang: $lang) {
    body {
      ... on HomepageBodyHero {
        type
        primary {
          imagemobile
          imagedesktop
          title
          link {
            ... on _ExternalLink {
              url
            }
          }
        }
      }
      ... on HomepageBodyBanner_full {
        type
        primary {
          image
          link {
            ... on _ExternalLink {
              url
            }
          }
        }
      }
      ... on HomepageBodyBanner_half_half {
        type
        primary {
          imageone
          linkone {
            ... on _ExternalLink {
              url
            }
          }
          imagetwo
          linktwo {
            ... on _ExternalLink {
              url
            }
          }
        }
      }
      ... on HomepageBodyBanner_onethird_twothird {
        type
        primary {
          imageonethird
          linkonethird {
            ... on _ExternalLink {
              url
            }
          }
          imagetwothird
          linktwothird {
            ... on _ExternalLink {
              url
            }
          }
        }
      }
      ... on HomepageBodyBanner_twothird_onethird {
        type
        primary {
          imageonethird
          linkonethird {
            ... on _ExternalLink {
              url
            }
          }
          imagetwothird
          linktwothird {
            ... on _ExternalLink {
              url
            }
          }
        }
      }
      ... on HomepageBodySeo_text {
        type
        primary {
          text
        }
      }
      ... on HomepageBodyBrands {
        type
        primary {
          title
        }
        fields {
          image
          link {
            ... on _ExternalLink {
              url
            }
          }
        }
      }
    }
  }
}
`
