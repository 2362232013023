import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '@emico/styles'
import { Container } from '@emico/layout'
import { PrismicRichText } from '@emico/prismic'

import { usePrismicSlice } from '../../__mocks__/usePrismicSlice'

const Wrapper = styled.div`
    background: ${theme.primaryColor};
`
const Text = styled(Container)`
    padding: 0;
    text-align: center;
    font-size: 14px;
    line-height: 28px;
    color: ${theme.onPrimaryColor};

    a {
        color: ${theme.onPrimaryColor};
    }
`

const Notice = () => {
    const { data } = usePrismicSlice('notice_banner')

    return (
        <Wrapper>
            <Text>
                {data?.primary?.notice && (
                    <PrismicRichText render={data.primary.notice} />
                )}
            </Text>
        </Wrapper>
    )
}

export default Notice
