import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { PageWrapper } from '@emico/layout'
import { normalizeUrl, useResolveUrl } from '@emico/navigation'

import NotFoundPage from '../components/NotFoundPage'
import DynamicRoutePage from './DynamicRoutePage'

const DynamicRouteResolver = ({ location }: RouteComponentProps) => {
    const pathname = location.pathname
    const { loading, error, data: resolvedPage } = useResolveUrl(pathname)
    const urlKey = normalizeUrl(pathname).substr(1)

    if (error) {
        throw error
    }

    if (loading) {
        return <PageWrapper pageType="N/A">Resolving url...</PageWrapper>
    }

    if (!resolvedPage || !resolvedPage.type) {
        return <NotFoundPage />
    }

    return (
        <DynamicRoutePage
            type={resolvedPage.type}
            id={resolvedPage.id}
            urlKey={urlKey}
        />
    )
}

export default DynamicRouteResolver
