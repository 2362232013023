import * as React from 'react'

import { CategoryPage, ProductPage } from '@emico/catalog-ui'
import { UnreachableCaseError } from '@emico/utils'
import { ResolvedUrlType } from '@emico/navigation'

import PrismicPage from '../components/PrismicPage'

const DynamicRoutePage = ({
    type,
    id,
    urlKey,
}: {
    type: ResolvedUrlType
    id: number | undefined
    urlKey: string
}) => {
    switch (type) {
        case 'CATEGORY':
            // id is always defined when the type is category
            // TODO: Using a lookup type (an object with key of type) we can enforce this. We would also need to update the Resolver type
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return <CategoryPage id={id!} />
        case 'PRODUCT':
            return <ProductPage urlKey={urlKey} />
        case 'CMS_PAGE':
            return <PrismicPage urlKey={urlKey} />
        default:
            throw new UnreachableCaseError(type)
    }
}

export default DynamicRoutePage
